<template>
  <div>
    <h1>Bienvenido a su apartado para capturar empleados</h1>
    <b-form @reset="onReset" >
      <b-form-group id="input-group-1" label="Nombre:" label-for="input-1">
        <b-form-input
          id="input-1"
          v-model="empleadoActual.nombre"
          placeholder="Ingrese el nombre del empleado"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" label="Apellido paterno:" label-for="input-2">
        <b-form-input
          v-model="empleadoActual.apellido_paterno"
          placeholder="Ingrese el apellido paterno del empleado">
        </b-form-input>
      </b-form-group>

      <b-form-group id="input-group-3" label="Apellido materno:" label-for="input-3">
        <b-form-input
          v-model="empleadoActual.apellido_materno"
          placeholder="Ingrese el apellido materno del empleado">
        </b-form-input>
      </b-form-group>

      <b-form-group id="input-group-4" label="RFC:" label-for="input-4">
        <b-form-input
          v-model="empleadoActual.rfc"
          placeholder="Ingrese el RFC del empleado">
        </b-form-input>
      </b-form-group>

   
    <b-button variant="dark" @click="enviarEmpleado" @dblclick="reloadPage">
      Enviar empleado
    </b-button>

    <b-button variant="dark" @click="reloadPage">
      Dar de alta otro empleado
    </b-button>

    <div>
    <br>
      <b-table striped hover :items="items"></b-table>
    </div>

    <br>
    <h6>Resumen empleado</h6>
    {{empleadoActual}}
    <br>
    <h6>Resumen Inventario Personas</h6>
    {{inventario_empleados}}

    <br>



    </b-form>

  </div>
</template>

<script>
import axios from 'axios'
  export default {
    data() {
      return {
        inventario_empleados: {
          empleados:[
            {}
          ]
        },

        empleadoActual:{
                nombre:'',
              apellido_paterno:'',
              apellido_materno:'',
              rfc: ''
            },
        
        items: [
          {nombre: 'Sergio', apellido_paterno: 'Rios', apellido_materno: 'Cruz', rfc: 'LOAA9503115H1'},

        ],
        
      }
    },
    methods: {
    
      onReset(event) {
        event.preventDefault()
        // Valores al Resetear encuesta

        this.empleadoActual.nombre=''
        this.empleadoActual.apellido_paterno=''
        this.empleadoActual.apellido_materno=''
      },

      reloadPage() {
      window.location.reload();
    },

      enviarEmpleado(){
        this.inventario_empleados.empleados.push(JSON.parse(JSON.stringify(this.empleadoActual)));
          alert('Se guardaron correctamente los datos del empleado');

          axios({
            method: 'get',
            url: 'https://recaudacion.gobinet.mx/servicios',
            params:{"codeClient":"calimaya"},
            data: {
                "idToken": '',
                "usuario":this.usuario,
                "pass":this.pass
            }
          })
          .then(res => {
              this.resultado = res.data;
          }).catch(err => {
              //errores
              alert(err);
          });
          },

    }
  }
</script>